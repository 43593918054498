import { useEffect, React, useState, useRef } from 'react';
import './assets/css/Reset.css';
import Header from './component/Header';
import Hero from './component/Hero';
import video from './assets/img/ICON_VERSION8-1-1.webm';
import preload from './assets/img/ICON_VERSION8-1-1-0.jpg';
import About from './component/About';
import Services from './component/Services';
import Cta from './component/Cta';
import WhyOffer from './component/WhyOffer';
import DataDriven from './component/DataDriven';
import Footer from './component/Footer';
import Copyright from './component/Copyright';
import useScrollSpy from 'react-use-scrollspy';
import ScrollToTop from "react-scroll-to-top";
import AOS from 'aos';
import 'aos/dist/aos.css';


function App() {
  const sectionRefs = [
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
  ];
  const activeSection = useScrollSpy({
    sectionElementRefs: sectionRefs,
    offsetPx: -100,
  });

  const [scroll, setScroll] = useState(false);

  useEffect(() => {
    AOS.init({
      offset: 150,
      duration: 600,
      easing: 'ease-in-sine',
      delay: 100,
      disable: 'mobile'
    });
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 50);
    });
  }, []);
  return (
    <div className="App">
      <header className={scroll ? "App-header fixed-top bg-scroll" : "App-header fixed-top"}>
        <Header activeSection={activeSection} />
      </header>
      <main>
        <section className='hero' ref={sectionRefs[0]}>
          <video id="background-video" autoPlay loop muted poster={preload}>
            <source src={video} type="video/mp4" />
          </video>
          <Hero />
        </section>
        <section className='about' id="about" ref={sectionRefs[1]}>
          <About />
        </section>
        <section className='services' id="services" ref={sectionRefs[2]}>
          <Services />
        </section>
        <section className='cta'>
          <Cta />
        </section>
        <section className='why' id="why" ref={sectionRefs[3]}>
          <WhyOffer />
        </section>
        <section className='dataDriven'>
          <DataDriven/>
        </section>
      </main>
      <footer id="contact" ref={sectionRefs[4]}>
        <div className='f-col'><Footer /></div>
        <div className='copyright'><Copyright/></div>
      </footer>
      <ScrollToTop smooth color="red"/>
    </div>
  );
}

export default App;
