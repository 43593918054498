import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Worldmap from '../assets/img/Worldmap.png';

function About() {
  return (
    <Container>
      <Row>
        <Col md={6} data-aos="zoom-in" data-aos-duration="1000">
            <img src={Worldmap} alt="Worldmap" />
        </Col>
        <Col md={6}>
            <h2 className='offers' data-aos="fade-in" data-aos-duration="1000">
                Learn more about <span className='red'>Offers Integrity</span>
            </h2>
            <p data-aos="fade-up" data-aos-duration="1000">
                <strong>Offers Integrity</strong> is operated by the industry’s leading online campaign management team.<br/><br/>
                We’ve championed a collaborative, people-focused approach to navigating the digital landscape to get results for our business partners. We’re a 360º agency that values the relationship between creative and data, between innovation and the tried-and-true, between automation and human insight. <br/><br/>
                Our business is personal. Brands stick with us. Employees stick with us. Vendors stick with us. Why? Because we stick with them. Our team is your team in everything we do.
            </p>
        </Col>
      </Row>
    </Container>
  )
}

export default About