import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ListGroup from 'react-bootstrap/ListGroup';
import { FaFacebookSquare } from "@react-icons/all-files/fa/FaFacebookSquare";
import { FaTwitterSquare } from "@react-icons/all-files/fa/FaTwitterSquare";
import { FaInstagramSquare } from "@react-icons/all-files/fa/FaInstagramSquare";

function Copyright() {
  return (
    <Container>
        <Row className='align-items-center'>
            <Col md={6}>
                <p>COPYRIGHT © 2022 Offers integrity. All Rights Reserved.</p>
            </Col>
            <Col md={6}>
                <ListGroup horizontal  className='float-end'>
                    <ListGroup.Item><FaFacebookSquare className='red'/></ListGroup.Item>
                    <ListGroup.Item><FaTwitterSquare className='red'/></ListGroup.Item>
                    <ListGroup.Item><FaInstagramSquare className='red'/></ListGroup.Item>
                </ListGroup>
            </Col>
        </Row>
    </Container>
  )
}

export default Copyright