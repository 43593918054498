import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ListGroup from 'react-bootstrap/ListGroup';
import { FaMapMarkerAlt } from "@react-icons/all-files/fa/FaMapMarkerAlt";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

function Footer() {
  return (
    <Container>
      <Row>
        <Col md={6} data-aos="fade-right" data-aos-duration="800">
            <p className='footer-logo'>
                <a href='/'><img src="../assets/img/offers-integrity.png" alt="OFFERS INTEGRITY"/></a>
            </p>
            <p className='text-white footer-desc'>
                We value your investment. Reach your target audience and pay only for target actions such as sales, bookings and sign-ups.
            </p>
            <ListGroup>
                <ListGroup.Item className='text-white'>
                    <FaMapMarkerAlt className='red'/>
                    601 Tijeras Ave NW Albuquerque, NM 87102
                </ListGroup.Item>
            </ListGroup>
        </Col>
        <Col md={6} data-aos="fade-left" data-aos-duration="800">
        <Form onSubmit={e=>e.preventDefault()} className='form-static'>
          <Row>
            <Col md={6}>
              <Form.Control placeholder="Full Name" name="fullname" type="text"/>
            </Col>
            <Col md={6}>
              <Form.Control placeholder="Email Address" name="email" type="email" />
            </Col>
            <Col md={6}>
              <Form.Control placeholder="Phone Number" name="phone" type="number" />
            </Col>
            <Col md={6}>
              <Form.Control placeholder="Address" name="address" type="text" />
            </Col>
            <Col md={12}>
              <Form.Select defaultValue="Choose...">
                <option>Choose...</option>
                <option>...</option>
              </Form.Select>
            </Col>
            <Col md={12}>
                <Form.Control
                  as="textarea"
                  placeholder="Message"
                  style={{ height: '100px' }}
                />
            </Col>
            
            <Col md={12}>
              <Button variant="primary" type="submit" className='float-end mt-2 cs-btn-form'>
                Submit
              </Button>
            </Col>
          </Row>
        </Form>
        </Col>
      </Row>
    </Container>
  )
}

export default Footer