import { React, useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import postData from '../assets/post.json'

function Services() {
    const [show, setShow] = useState([]);
    useEffect(() => {
        setShow(postData);
    }, []);
    return (
        <Container>
            <Row>
                <Col data-aos="fade-down" data-aos-duration="1000">
                    <h2 className='text-center offers ser'>
                        How can <span className='red'><strong>Offers Integrity</strong></span> help you??
                    </h2>
                </Col>
            </Row>
            <Row  className="justify-content-lg-center post-grid">
                {
                    show.map((res, id)=>(
                        <Col sm={12} md={6} lg={4} key={id} className='p-3'  data-aos="fade-up" data-aos-duration={`${id*2}00`}>
                            <Card style={{width:"100%", height:"100%"}}>
                                <div className='card-img-extra-paddng'><Card.Img variant="top" src={`assets/img/${res.img}`} /></div>
                                <Card.Body>
                                    <Card.Title className='red'>{res.title}</Card.Title>
                                    <Card.Text>{res.desc}</Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                    ))
                }
            </Row>
        </Container>
    )
}

export default Services