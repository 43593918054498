import React from 'react';
import Typewriter from 'typewriter-effect';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';


function Hero() {
    
  const scrollToElement = (id) => {
    const container = document.getElementById(id.substring(1));
    container.scrollIntoView({ behavior: 'smooth', block: 'start'});
  };
  return (
    <Container  className='heroSection'>
        <Row className="justify-content-md-center align-items-sm-center">
            <Col>
                <p className='subHeading text-center text-white cs-shadow' data-aos="fade-down" data-aos-duration="500">SAY HELLO TO</p>
                <h1 className='text-center text-white cs-shadow' data-aos="zoom-in" data-aos-duration="1500" data-aos-delay="1000">OFFERS INTEGRITY</h1>
                <div className="text-center text-white cs-shadow typetext">
                    <Typewriter
                        options={{
                            strings: ['The only way to succeed in online marketing is to partner with the best'],
                            autoStart: true,
                            loop: true,
                        }}
                    />
                </div>
                <div className='text-center'><Button variant="outline-primary"  data-href="#contact" className='btn-cs'  size="lg" onClick={(e) => scrollToElement(e.target.getAttribute('data-href'))}>Join us now</Button></div>
            </Col>
        </Row>
    </Container >
    
  )
}

export default Hero