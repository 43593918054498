import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

function Cta() {
  const scrollToElement = (id) => {
    const container = document.getElementById(id.substring(1));
    container.scrollIntoView({ behavior: 'smooth', block: 'start'});
  };
  return (
    <Container>
      <Row className='justify-content-md-center align-items-md-center'>
        <Col md={6} data-aos="fade-right" data-aos-duration="1000">
            <p className='cta-text'>Brands should pay when their partners deliver true ROI, not fancy PowerPoint presentations.</p>
        </Col>
        <Col md={6} data-aos="fade-left" data-aos-duration="1000">
            <div className='text-center'><Button variant="outline-primary" className='btn-cs-2'  data-href="#contact"   size="lg" onClick={(e) => scrollToElement(e.target.getAttribute('data-href'))}>Contact US</Button></div>
        </Col>
      </Row>
    </Container>
  )
}

export default Cta