import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import { FaAddressCard } from "@react-icons/all-files/fa/FaAddressCard";
import { FaChartBar } from "@react-icons/all-files/fa/FaChartBar";
import { FaDollarSign } from "@react-icons/all-files/fa/FaDollarSign";
import { FaComments } from "@react-icons/all-files/fa/FaComments";

function WhyOffer() {
  return (
    <Container>
        <Row>
            <Col data-aos="fade-up" data-aos-duration="1000">
                <h2 className='text-center offers ser'>
                    Why work with <span className='red'><strong>Offers Integrity</strong></span>
                </h2>
            </Col>
        </Row>
        
        <Row  className="justify-content-lg-center post-grid">
            <Col sm={12} md={6} lg={6} className='p-3' data-aos="fade-up" data-aos-duration="800">
                <Card style={{width:"100%", height:"100%"}}>
                    <div className='card-img-extra-paddng'><FaAddressCard  /></div>
                    <Card.Body>
                        <Card.Title className='red text-center'>Direct Access To Exclusive Offers</Card.Title>
                        <Card.Text className='text-center'>You will have direct access to exclusive offers which we manage, making your campaigns much more competitive against the competition.</Card.Text>
                    </Card.Body>
                </Card>
            </Col>
            <Col sm={12} md={6} lg={6} className='p-3' data-aos="fade-up" data-aos-duration="1000">
                <Card style={{width:"100%", height:"100%"}}>
                    <div className='card-img-extra-paddng'><FaChartBar  /></div>
                    <Card.Body>
                        <Card.Title className='red text-center'>Real Time Stats & Transparency</Card.Title>
                        <Card.Text className='text-center'>Leverages the same enterprise-class, industry-standard tracking and analytics platform that most large networks use, allowing you uniformity in your data and ease of pixel placement.</Card.Text>
                    </Card.Body>
                </Card>
            </Col>
            <Col sm={12} md={6} lg={6} className='p-3' data-aos="fade-up" data-aos-duration="1200">
                <Card style={{width:"100%", height:"100%"}}>
                    <div className='card-img-extra-paddng'><Card.Img variant="top" /><FaDollarSign /></div>
                    <Card.Body>
                        <Card.Title className='red text-center'>Immediate Payments. No Exceptions.</Card.Title>
                        <Card.Text className='text-center'>Your cash flow is key! And payment and transparency are our top priorities. At minimum, you will be paid weekly for your traffic. We are happy to augment and personalize your payment experience by request, if you need to be paid faster.</Card.Text>
                    </Card.Body>
                </Card>
            </Col>
            <Col sm={12} md={6} lg={6} className='p-3' data-aos="fade-up" data-aos-duration="1400">
                <Card style={{width:"100%", height:"100%"}}>
                    <div className='card-img-extra-paddng'><FaComments /></div>
                    <Card.Body>
                        <Card.Title className='red text-center'>Constant Communication</Card.Title>
                        <Card.Text className='text-center'>You will have a dedicated contact to manage your account. In addition, Offers integrity is unique in that we manage our offers, so we are able to provide unique feedback and campaign guidance as needed.</Card.Text>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
  </Container>
  )
}

export default WhyOffer