import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Accordion from 'react-bootstrap/Accordion';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


function DataDriven() {

    var settings = {
        dots: false,
        arrows: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        centerPadding: '60px',
        responsive: [
            {
              breakpoint: 767,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1
              }
            },
            {
                breakpoint: 425,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };
  return (
    <Container>
      <Row>
        <Col className='first-col'  md={6}>
            <h2 data-aos="zoom-in" data-aos-duration="800">Data Driven <strong className='red'>Marketing Solution</strong></h2>
            <p data-aos="fade-up" data-aos-duration="800">Offers Integrity is a data-driven digital marketing leader providing premium media solutions to the world’s leading brands and agencies.</p>
            <div className='solo-img' data-aos="fade-right" data-aos-duration="800"><img src='../assets/img/business-woman-making-presentation-office-1-980x654.jpg' alt="Business Woman Making Presentation" /></div>
        </Col>
        <Col className='accordion-section' md={6} data-aos="fade-left" data-aos-duration="800">
        <Accordion defaultActiveKey={['0']}> 
            <Accordion.Item eventKey="0">
                <Accordion.Header>Global Reach</Accordion.Header>
                <Accordion.Body>
                 We run performance campaigns for thousands of international brands across multiple industry verticals. We have the international reach and local expertise necessary to deliver successful campaigns in any market.
                </Accordion.Body>
            </Accordion.Item>   
            <Accordion.Item eventKey="1">
                <Accordion.Header>Technology</Accordion.Header>
                <Accordion.Body>
                Through a combination of cutting-edge technology and performance advertising expertise, we are able to drive optimal results for our advertisers. Using thousands of unique proprietary data points, our technology makes accurate monetary predictions, executing campaigns to maximize brand lift and profit.
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
                <Accordion.Header>Expertise</Accordion.Header>
                <Accordion.Body>
                Demonstrating thought leadership for over a decade, we have expertise in all digital marketing disciplines and deliver high-quality global distribution through display, e-mail, social, mobile, video and search.
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3">
                <Accordion.Header>Success</Accordion.Header>
                <Accordion.Body>
                Our technology ensures intelligent ad placement, guaranteeing effective budget allocation and exceptional results. We partner with the world’s largest brands, consistently exceeding client success metrics.
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
        </Col>
      </Row>
      <Row className='slider-section'>
        <Col  data-aos="fade-up" data-aos-duration="800">
        <Slider {...settings}>
            <div className='slider-area'>
                <img src='../assets/img/Rev-content.jpg' alt='Partner 1' />
            </div>
            <div className='slider-area'>
                <img src='../assets/img/Partners-3.jpg' alt='Partner 2'/>
            </div>
            <div className='slider-area'>
                <img src='../assets/img/Partners-4.jpg' alt='Partner 3' />
            </div>
            <div className='slider-area'>
                <img src='../assets/img/Partners-1.jpg' alt='Partner 4'/>
            </div>
        </Slider>
        </Col>
      </Row>
    </Container>
  )
}

export default DataDriven