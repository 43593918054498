import React from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Offcanvas from 'react-bootstrap/Offcanvas';
import logo from '../assets/img/offers-integrity.png';

function Header({ activeSection }) {
  const scrollToElement = (id) => {
    const container = document.getElementById(id.substring(1));
    container.scrollIntoView({ behavior: 'smooth', block: 'start'});
  };
  return (
    <>
        {['lg'].map((expand) => (
        <Navbar key={expand} expand={expand} className="bg-body-tertiary mb-3" collapseOnSelect>
          <Container>
            <Navbar.Brand href="/"><img src={logo} alt="Offers Integrity" className="main-logo"/></Navbar.Brand>
            <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />
            <Navbar.Offcanvas
              id={`offcanvasNavbar-expand-${expand}`}
              aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
              placement="end"
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                <img src={logo} alt="Offers Integrity" className="main-logo"/>
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <Nav className="justify-content-end flex-grow-1 pe-3">
                  <Nav.Link eventKey="0" data-href="#about" className={activeSection === 1 ? "text-white item--active" : "text-white"} onClick={(e) => scrollToElement(e.target.getAttribute('data-href'))}>About us</Nav.Link>
                  <Nav.Link eventKey="1" data-href="#services" className={activeSection === 2 ? "text-white item--active" : "text-white"} onClick={(e) => scrollToElement(e.target.getAttribute('data-href'))}>Services</Nav.Link>
                  <Nav.Link eventKey="2" data-href="#why" className={activeSection === 3 ? "text-white item--active" : "text-white"} onClick={(e) => scrollToElement(e.target.getAttribute('data-href'))}>Why Offers integrity</Nav.Link>
                  <Nav.Link eventKey="3" data-href="#contact" className={activeSection === 4 ? "text-white item--active" : "text-white"} onClick={(e) => scrollToElement(e.target.getAttribute('data-href'))}>Contact</Nav.Link>
                </Nav>
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </Container>
        </Navbar>
      ))}
    </>
  )
}

export default Header